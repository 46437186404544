@import "~bootstrap/dist/css/bootstrap.min.css";
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700;900&family=Ubuntu+Mono:wght@400;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:wght@100;300;400;700;900&family=Ubuntu+Mono:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  /* font-family: "Roboto"; */
}

:root {
  --font-size-large: 3rem;
  --font-size-small: 0.875rem;
  
  --font-family-primary: "Roboto";
  --font-family-secondary: "Montserrat";
  --text-color-secondary: #3d3d3db2;

}

.form-control {
  padding: 10px;
  font-family: "Roboto";
  outline: none;
  /* border: none; */
}

.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #ced4da; /* Optionally, you can set the border color to match Bootstrap's default */
}
