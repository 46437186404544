a.companyLogo {
  transform: translateX(3vmax);
  /* font-size: 1vmax; */
  text-decoration: none !important;
  color: #110a54;
}

a.companyLogo img {
  max-width: 100%;
  height: auto;
}
