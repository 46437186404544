.dashboard-heading h1 {
  font-family: "Montserrat";
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.5rem;
  letter-spacing: 0em;
  text-align: left;
}

.dashboard-heading p {
  font-family: "Montserrat";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0em;
  /* text-align: center; */
}
