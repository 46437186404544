.custom-nav-bar {
  box-shadow: 0px 2px 10px 6px rgba(0, 0, 0, 0.1);
  /* z-index: 99999999; */
  display: flex;
  z-index: 2;
}

.logout-btn,
.logout-btn:hover,
.logout-btn:active {
  background: #7f88e7;
  color: white;
}

.form-inline {
  padding-right: 1vmax;
}
