#coin-star {
  background: url("../../assets/images/Group.png");
  background-position: center;
  background-repeat: no-repeat;
}

#coin-star > img {
  width: 150px;
}

.points-box {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: Mixed solid #d9d9d9;
  border-radius: 0.5px, 0px, 0.5px, 0.5px;
  position: relative; /* Required for absolute positioning of ::after pseudo-element */
}

.points-box p {
  margin-bottom: 0;
  color: #959292;
  font-weight: 500;
  font-size: 0.85rem;
}

.points-box h5 {
  font-weight: 600;
}

.points-box > div {
  padding: 0.2vmax 3vmax;
  border-radius: 0.5px, 0px, 0.5px, 0.5px;
  border: 0.5px solid #d9d9d9;
  position: relative; /* Ensure positioning context for absolute ::after */

  /* border-width: 0.5px, 1px, 0.5px, 0.5px; border-style: solid; border-color: #D9D9D9; */
}

.points-box > div:first-child:after {
  content: ""; /* Required for pseudo-element */
  position: absolute;
  top: 50%;
  right: -10px; /* Adjust this value to position the icon */
  transform: translateY(-50%);
  width: 20px; /* Adjust size of the icon */
  height: 20px; /* Adjust size of the icon */
  /* background-color: #d9d9d9; Adjust color of the icon */
  background: url("../../assets/images/Mapto.png");
  background-size: cover;
  border-radius: 50%; /* Makes the icon circular */
  z-index: 1111;
}
