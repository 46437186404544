.payment-history {
    font-family: var(--font-family-secondary);
}

.payment-history h3 {
  font-family: var(--font-family-secondary);
  font-weight: 600;
}

.payment-history h5 {
  font-family: var(--font-family-secondary);
  font-weight: 500;
  font-size: 18px;
}

.payment-history h5 > span { 
    font-size: 14px;
    color: #3D3D3D80;

}

.voilet {
  color: #7f88e7;
}

.status{
  padding: 5px 10px;
  background: #1BC094B2;
  color: white;
  border-radius: 10px;
}