.splash-screen {
  background: linear-gradient(
    270.44deg,
    rgba(135, 139, 183, 0.2) -1.69%,
    rgba(255, 255, 255, 0) 96.07%
  );
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.splash-screen > h1 {
    color: #00008c;
    font-weight: 700;
    font-size: 4vmax;
    font-family: var(--font-family-primary) ;
}

/* .splash-screen a{

    transform: translateX(3vmax);
    text-decoration: none;
    color:  #110A54;
}
.splash-screen img {
    max-width: 100%;
    height: auto;
} */
