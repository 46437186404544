.subscripton_card {
  margin: 1.2vmax;
  padding: 3vmax;
  /* background: linear-gradient(
    180deg,
    rgba(127, 136, 231, 0.08) 0%,
    rgba(255, 255, 255, 0.0272) 100%
  );  -- main --  */

  background: linear-gradient(
    180deg,
    rgb(127 136 231 / 19%) 0%,
    rgba(255, 255, 255, 0.0272) 100%
  );
  /* background:  linear-gradient(180deg, rgb(127 136 231) 0%, rgba(255, 255, 255, 0.0272) 100%); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: var(--font-family-primary);
  text-align: center;
  /* transition: all 0.3s; */
  transition: transform ease-in-out 0.2s;
}

.subscripton_card span.larger-font {
  font-size: 42px;
  font-weight: 600;
}

.subscripton_card > h4 {
  font-weight: 500;
  font-size: 16px;
}

.subscripton_card > h3 {
  color: #7f88e7;
}

.subscripton_card li {
  list-style-type: circle;
}

.subscribe-btn {
  background: transparent;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #7f88e7;
  color: #7f88e7;
}

.subscribe-btn:hover,
.subscribe-btn:active {
  background-color: #7f88e7;
  color: white;
}

.subscripton_card:hover {
  box-sizing: border-box;
  cursor: pointer;
  transform: scale(1.05);
  border-top: 2px solid #7f88e7;
  border-right: 2px solid linear-gradient(#7f88e7, white);
  border-image: linear-gradient(to bottom, #7f88e7, white) 1;
}

@media only screen and (max-width: 768px) {
  .subscripton_card {
    width: 90vw;
    margin: auto;
  }
}
