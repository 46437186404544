.offers::-webkit-scrollbar {
  width: 20px;
}

/* Small devices (portrait tablets and large phones, 576px and up) */
/* Extra small devices (phones, 576px and down) */
@media (max-width: 576px) {
  /* CSS styles for phones */
  .offers::-webkit-scrollbar {
    width: 10px;
  }
}

/* Track */
.offers ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.offers::-webkit-scrollbar-thumb {
  background: #7f88e7;
  border-radius: 10px;
}

/* Handle on hover */
.offers::-webkit-scrollbar-thumb:hover {
  background: #7f88e7;
}
