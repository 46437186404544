/* .Login{
    height: 100vh;
} */

.custom-image {
  height: 100vh; /* Default height for desktop */
  width: 100%;
}

@media (max-width: 767px) {
  /* Mobile view */
  .custom-image {
    height: 50vh; /* Height for mobile */
  }

  .login-form {
    display: block !important;
  }
}

.login-form {
  margin-top: 7vmax;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  text-align: center;
}

.heading h1 {
  font-family: "Roboto";
  font-weight: 600;
  /* font-size: 48px;
  line-height: 56.25px */
}

.heading h5 {
  font-family: "Roboto";
  font-weight: 400;
  /* font-size: 20px;
  line-height: 23.44px   */
}

a.forget-password {
  text-decoration: none;
  color: #878bb7;
}

.rememeber-box {
  font-size: 0.8rem;
  font-family: "Roboto";
}

.btn-purple {
  padding: 10px;
  /* background: linear-gradient(189.75deg, #747dd7 -20.86%, #223faf 92.96%); */
  background: #7f88e7;
  font-size: 1.25rem;
}

.btn-purple:focus {
  background: #7f88e7;
}

.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active {
  background-color: #7f88e7;
}

.append-btn {
  border: none;
  /* background: transparent; */
 
  /* padding: 5px; */
}

.append-btn img{
  height: 30px;
  width: 30px;
  padding: 5px 2px ;
  margin: 5px;
}

