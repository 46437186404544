.subscription_plan {
  /* margin: 1vmax;
  padding: 1vmax;   */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subscription_heading {
  margin-top: 2vmax;
  max-width: 600px;
}

.subscription_heading > h1 {
  font-family: var(--font-family-secondary);
  /* font-size: var(--font-size-large); */
  font-weight: 700;
  line-height: 58.51px;
  text-align: center;
}

.subscription_heading > p {
  font-family: var(--font-family-secondary);
  color: var(--text-color-secondary);
  /* font-size: var(--font-size-small) ; */
  font-weight: 400;
  text-align: center;
  padding: 1vmax;
}



.radio-btn-label {
  margin-left: 8px;
  margin-right: 23px;
  font-family: var(--font-family-secondary);
  font-weight: 400;
}

input[type="radio"] {
  accent-color: black; /* Optional: Change border color if necessary */
}

.subscription_container{
    max-width: 1400px;
}

