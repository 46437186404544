.upgrade-plan-card {
  /* box-shadow: 0px 4px 25px 0px #3D3D3D26;
     */
  border-radius: 20px;
  box-shadow: 0px 4px 25px 0px #3d3d3d26, rgba(116, 125, 215, 0.4) 10px 10px,
    rgba(116, 125, 215, 0.3) 20px 20px, rgba(116, 125, 215, 0.2) 30px 30px,
    rgba(116, 125, 215, 0.1) 40px 40px;
  /* rgba(116, 125, 215, 0.05) 50px  50px; */
  /* height: 300px; */
}

.current-plan-card {
  border-radius: 20px;
  box-shadow: 0px 4px 25px 0px #3d3d3d26;
  margin-bottom: 50px;
}

.upgrade-plan .btn {
  position: absolute;
  left: 32%;
  transform: translateY(-10px);
}

.upgrade-plan h3 {
  font-size: 20px;
  /* line-height:24.38px */
}

.upgrade-plan h5 {
  font-size: 14px;
}

.upgrade-plan p {
  font-size: 14px;
}

.upgrade-plan h3,
h5,
p {
  margin-bottom: 5px;
}

.cancel-subscription {
 background: #3D3D3DCC;
  color: white;
  box-shadow: 0px 4px 4px 0px #00000026;
  border: none;
  padding: 3px 15px;
  margin-top: 5px;
  border-radius: 30px
} 
