.payment_method {
  /* margin: 2vmax; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.stripe-btn , .stripe-btn:hover , .stripe-btn:active {
  background-color: #635bff  !important;
  color: white  !important;
  padding: 5px 10px; /* Adjust padding as needed */
  font-size: xx-small; /* Adjust font size to make it smaller */
}



.payment_heading {
  text-align: center !important;
  font-family: 'Montserrat';
  margin: 1.2vmax 0; 
}

.payment_heading > h1{
    font-weight: 700;
}

.payment_detail {
  /* padding-left: 9%;   */
  /* background-color: #e2eff5; */
}

.btn-design {
  margin-top: 3vmax !important;
  padding: 3px 7px;
  border-radius: 50px;
  /* Adjust border-radius to make it pill-shaped */
  /* font-size: 16px;  */
}

.custom-input {
  width: 100%;
  border: 0.3px solid #ced4da;
  font-size: 16px;
  height: 4.1vh;
}

.custom-input:focus {
  outline: none;
  box-shadow: none;
  border-color: #ced4da;
}

.custom-input::placeholder {
  font-size: small;
  color: rgb(160, 155, 155);
}

.server-text {
  text-align: end;
  color: rgb(160, 155, 155);
}

.card-number-container {
  position: relative;
}

.card-brand-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

/* .payment_method input {
    font-size: 12px;
}  */

.payment_method input {
  padding: 7px;
  font-size: 15px;
}

.payment_method .form-label {
  font-weight: 600;
  font-family: "Montserrat";
}

@media (max-width: 1200px) {
  .strip-form {
    width: 100%; /* Adjust width for smaller desktop screens */
  }
}

@media (max-width: 992px) {
  .strip-form {
    width: 95%; /* Adjust width for tablets */
  }
}

@media (max-width: 768px) {
  .strip-form {
    width: 100%; /* Adjust width for mobile devices */
    margin-top: 20px; /* Adjust margin for mobile devices */
  }
}

@media (min-width: 1300px) {
  .strip-form {
    width: 85%; /* Adjust width for screens wider than 1300px */
  }
}
