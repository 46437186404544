.upload {
  /* max-width: ; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btn-box {
  display: flex;
}

.btn-box button {
  margin: 0.4vmax;
}

.btn-icon {
  border: none;
  background: transparent;
}

button.upload-btn {
  font-family: Montserrat;
  /* font-size: 1.25rem; */
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  background: #7f88e7;
  padding: 0 1vmax;
  color: white;
  border: none;
  padding: 0.5vmax 1vmax;
  /* text-align: center; */
}

button.white,
.white {
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  box-shadow: 0px 2px 5px 0px #00000040;
  border: none;
  padding: 0.5vmax 1vmax;
  background: transparent;
}

.btn-box > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .upload {
    flex-direction: column;
  }
}
