.referal-program > .referalheading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 900px;
  margin: 2vmax 1vmax;
  font-family: Montserrat;
}

.referal-program > main {
  max-width: 900px;
}

.referal-program > main .head {
  max-width: 700px;
}

.referal-program > main .head > h5 {
  font-weight: 600;
}

.referal-program p {
  color: #3d3d3d80;
  font-weight: 500;
}

.referal-program a {
  text-decoration: none;
  color: #7f88e7;
}

.img-container {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  margin-top: 2vmax;
}

.img-container:nth-child(2) > img {
  padding: 1vmax 3vmax;
}

.img-container img {
  width: 100%;
}

.spread-image > div:first-child {
  position: relative;
  right: 89px;
}
.spread-image > div:last-child {
  position: relative;
  left: 89px;
  top: 70px;
}

/* here  */
.images-container {
    margin-top: 2vmax;
  background: url("../../../../assets/images/FullCoinImage.png");
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
}

.desktop {
  transform: translateX(calc(100% + 150px));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
