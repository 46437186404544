.done-btn,
.done-btn:active,
.done-btn:hover {
  background-color: #635bff;
}

.img-div {
  /* border-radius: 50%;
  background-color: #635bff;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: cover; */
  /* background-image: url("../../assets/images/Profile.png"); */
}

.cross-btn {
  border: none;
}

.cross-div {
  margin-top: -1px;
}

.MuiDialog-paperScrollPaper {
  overflow: hidden !important;
  max-height: none !important;
}
.custom-dialog-paper {
  overflow: hidden;
  max-height: none;
}
