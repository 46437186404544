/* App.css */

* {
  margin: 0;
  padding: 0;
}

ul {
  padding-left: 0rem !important;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Set full height */
}

.header {
  /* background-color: #333; */
  color: #fff;
  padding: 10px;
  box-shadow: 0px 2px 10px 6px rgba(0, 0, 0, 0.1);
}

.content-wrapper {
  display: flex;
  flex: 1;
}

.sidebar {
  width: 20%;
  /* background-color: #f4f4f4; */
  padding: 10px;
  transition: transform 0.3s ease-in-out;
}

@media (min-width: 1024px) and (max-width: 1240px) {
  .sidebar {
    width: 30%;
  }
}

.sidebar.show {
  transform: translateX(0);
}

.main-content {
  flex: 1;
  padding: 10px;
}

.sidbar-menu li {
  list-style: none;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  /* padding: 10px 10px; */
  margin-bottom: 7px;
}

/* .sidbar-menu li:hover , .sidbar-menu li:active {
  box-shadow: 0px 2px 10px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
} */

.sidbar-menu li a {
  text-decoration: none;
  color: #3d3d3d;
  display: block;
  padding: 10px;
}

.sidbar-menu li a:hover {
  box-shadow: 0px 2px 10px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

/* .sidbar-menu li a {
  box-shadow: 0px 2px 10px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
} */

.selected {
  box-shadow: 0px 2px 10px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.sidbar-menu ul {
  padding-left: 0 !important;
}

.bi {
  margin-right: 10px;
  margin-bottom: 4px;
}

.right-arrow::after {
  display: inline-block;
  content: " ";
  background-image: url("../../assets/images/sidebar-right.png");
  width: 6px;
  height: 10px;
  background-size: cover;
  /* position: relative; */
  transform: rotate(0deg);
  /* margin-left: 20px; */
}

.right-arrow-rotated::after {
  display: inline-block;
  content: " ";
  background-image: url("../../assets/images/sidebar-right.png");
  width: 6px;
  height: 10px;
  background-size: cover;
  /* position: relative; */
  transform: rotate(90deg);
  /* margin-left: 20px; */
}

.main-content {
  padding: 1vmax;
}

@media (max-width: 768px) {
  .content-wrapper > aside.show {
    display: block;
  }
  .content-wrapper > aside.show {
    display: none;
  }

  .sidebar {
    width: 100%;
  }
}

.sidebar-show {
  width: 100vw;
  height: 100%;
}

.voilet-btn {
  background-color: #7f88e7;
  font-weight: 600;
  color: white;
  padding: 5px 9px;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 4px 4px 0px #00000040;
}
