.forgetPassword-container {
  margin-top: 8vmax;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgetPassword-container > div.box {
  background: linear-gradient(
    180deg,
    rgba(116, 125, 215, 0.2) -2.42%,
    rgba(255, 255, 255, 0.2) 100%
  );
  padding: 3vmax;
  border-radius: 12px;
  box-shadow: 0px 4px 8px 4px #0000001a;
  max-width: 600px;
}

.btn-white {
  padding: 10px;
  background: white;
  font-size: 1.25rem;
  border: none;
  color: black;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active {
  background-color: white !important;
  color: black !important; /* Ensure text color stays blue on hover, focus, and active states */
}
