.bonus-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .icon {
    margin-right: 10px;
    color: #007bff;
    font-size: 1.5em;
  }
  
  .bonus-header {
    font-size: 1em;
    font-weight: bold;
    color: #333;
    /* margin-bottom: 10px; */
  }
  
  .bonus-description {
    font-size: 1em;
    color: #666;
  }