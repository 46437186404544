.input-with-icon {
  position: relative;
}

.input-icon {
  position: absolute;
  left: 10px; /* Adjust the left position as needed */
  top: 45%;
  transform: translateY(-50%);
  pointer-events: none;
}

.input-icon-last {
  position: absolute;
  left: 95%;
  top: 7px;
}

.input-icon i {
  color: #ccc; /* Adjust the color of the icon */
}

.input-icon > img,
.input-icon-last > button > img {
  width: 15px;
}

.input-icon-last > button {
  background: transparent;
  border: none;
}


