.comming-soon {
    background-color: transparent;
    border: 1px solid #7F88E7;
    font-size: 8px;
    padding: 0px 3px;
    margin-left: 5px;
    margin-bottom: 2px;
    transform: translateY(-3px);
}

.comming-soon > img{
    transform: translateY(-2px);
    margin-left: 3px;
}

