.payment-dailog-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3vmax 6vmax 0vmax 6vmax !important
}

.payment-dailog-content > h3{
    margin-top: 1vmax;
    font-family: var(--font-family-primary);
    font-weight: 700;
    color:#1BC094;
}

.closebtn{
    border: none;
    padding: 0.5vmax 2vmax;
    margin-bottom: 1vmax;
    border-radius: 10px;
    font-size: 22px;
    font-family: var(--font-family-primary);
    font-weight: 700;
    color: white;
    background-color: #747DD7;
    /* text-align: center !important; */
}