h5.text {
  margin: 0;
  padding: 0;
}

.avatar:hover {
  background: url("../../assets/images/dropdown.png");
  height: 10px;
  width: 10px;
  position: absolute;
  left: 10px;
}

button.porfile-btn {
  padding: 0.1vmax 2.5vmax;
  border: 1.5px solid #7f88e7;
  background: transparent;
  border-radius: 20px;
  transition: 0.2s ease-in-out;
}

button.porfile-btn:hover {
    color: white;
    background-color: #7f88e7;
}

.profile-links{
  text-decoration: none !important;
  color: black;
}